@import "styles/_variables.scss";

.reviews {
    :global {
        .swiper {
            height: 100%;
        }
    }

    &__title {
        color: $mainText;
        text-align: center;
        font-family: $fontFamily;
        font-size: 28px;
        font-weight: 700;
        line-height: 1.66;
    }

    &__carousel {
        
        :global {
            .swiper-button-prev {
                left: 0;
                position: absolute;
                z-index: 10;
                top: 50%;
                transform: translate(0, -50%);
                width: 48px;
                height: 48px;
                border-radius: 50%;
                border: none;
                transition: background-color .4s ease;
                background-color: #FCFBF8;
                border: 1px solid $greenBackground;

                @media(max-width: $mobilePoint) {
                    display: none;
                }
            }

            .swiper-button-next {
                right: 0;
                position: absolute;
                z-index: 10;
                top: 50%;
                transform: translate(0, -50%);
                width: 48px;
                height: 48px;
                border-radius: 50%;
                border: none;
                transition: background-color .4s ease;
                border: 1px solid $greenBackground;
                background-color: #FCFBF8;

                @media(max-width: $mobilePoint) {
                    display: none;
                }
            }

            .swiper-button-prev::after {
                content: '';
                width: 24px;
                height: 24px;
                background: url('/assets/icons/icn-arrow-left.svg') no-repeat center;
            }

            .swiper-button-next::after {
                content: '';
                width: 24px;
                height: 24px;
                background: url('/assets/icons/icn-arrow-right.svg') no-repeat center;
            }

            .swiper-horizontal>.swiper-pagination-bullets,
            .swiper-pagination-bullets.swiper-pagination-horizontal,
            .swiper-pagination-custom,
            .swiper-pagination-fraction {
                bottom: 0px;
            }

            .swiper-pagination {
                bottom: 0px;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .swiper-pagination-bullet {
                background-color: $greenBackground;
                opacity: .3;
                transition: background-color .3s ease;
            }

            .swiper-pagination-bullet-active {
                width: 12px;
                height: 12px;
                background-color: $greenBackground;
                opacity: 1;
                transition: background-color .3s ease;
            }
        }

        &__item {
            max-width: 732px;
            text-align: center;
            margin: 0 auto;
            padding: 40px 0 40px;

            @media(max-width:1090px) {
                max-width: 600px;
            }

            @media(max-width: $mobilePoint) {
                min-height: 350px;
            }

            @media(max-width:540px) {
                max-width: 400px;
                padding: 0;
            }

            &__comment {
                color: $mainText;
                margin: 0 0 20px;
                font-size: 16px;
                font-weight: 400;
                font-family: $fontFamily;
                line-height: 26px;
            }

            &__specialistName {
                color: $mainText;
                margin: 0;
                font-size: 20px;
                font-weight: 500;
                font-family: $fontFamily;
                line-height: 32px;
            }

            &__company {
                color: $mainText;
                margin: 0;
                font-size: 16px;
                text-align: center;
                font-weight: 400;
                font-family: $fontFamily;
                line-height: 1.6;
            }
        }
    }
}